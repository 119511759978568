<template>
  <div class="win-loss-main">
    <div class="comparision-table-main">
      <div class="Rtable">
        <div class="Rtable-row header">
          <div class="Rtable-cell blank th"></div>
          <div class="Rtable-cell th background">
            <div class="each-dropdown">
              <SingleSelectorVue
                class="p1"
                label=""
                placeholder="Select product"
                :list="
                  productList.filter((p) => p.id !== wlSelectedProductRight.id)
                "
                :showRadio="true"
                :selected="wlSelectedProductLeft"
                @onChange="onChangeProductLeft"
                @open="HandelDropdownToggle('left', 'open')"
                @close="HandelDropdownToggle('left', 'close')" />
            </div>
          </div>
          <div class="Rtable-cell th background vs">vs</div>
          <div class="Rtable-cell th background">
            <div class="each-dropdown">
              <SingleSelectorVue
                class="p1"
                label=""
                placeholder="Select product"
                :list="
                  productList.filter((p) => p.id !== wlSelectedProductLeft.id)
                "
                :showRadio="true"
                :selected="wlSelectedProductRight"
                @onChange="onChangeProductRight"
                @open="HandelDropdownToggle('right', 'open')"
                @close="HandelDropdownToggle('right', 'close')" />
            </div>
          </div>
        </div>
        <div class="Rtable-row sticky-row">
          <div class="Rtable-cell empty"></div>
          <div class="Rtable-cell empty"></div>
          <div class="Rtable-cell empty"></div>
          <div class="Rtable-cell empty"></div>
        </div>

        <div class="Rtable-row">
          <div class="Rtable-cell row-title">
            <tr>
              <td>Top-rated Sentiments:</td>
              <td>
                <img
                  src="../../assets/info-new.svg"
                  alt=""
                  @mouseenter="tooltip($event, strengthTitle, 'in')"
                  @mouseleave="tooltip($event, strengthTitle, 'out')"
                  @mousemove="tooltip($event, strengthTitle, 'in')"
                  class="pl-2" />
              </td>
            </tr>
          </div>
          <div class="Rtable-cell background chart-container gauge-c">
            <GaugeChart
              v-if="
                product1Strengths && product1Strengths.length && !overallNoData
              "
              :data="product1Strengths"
              :chartNumber="1"
              :key="product1Strengths.map((d) => d.score).join(',')" />
            <div class="not-found" v-else>
              <no-data-container-vue />
            </div>
          </div>
          <div class="Rtable-cell"></div>
          <div class="Rtable-cell background chart-container gauge-c">
            <GaugeChart
              v-if="
                product2Strengths && product2Strengths.length && !overallNoData
              "
              :data="product2Strengths"
              :chartNumber="2"
              :key="product2Strengths.map((d) => d.score).join(',')" />
            <div class="not-found" v-else>
              <no-data-container-vue />
            </div>
          </div>
        </div>

        <div class="Rtable-row">
          <div class="Rtable-cell row-title">
            <tr>
              <td>Lowest-rated Sentiments:</td>
              <td>
                <img
                  src="../../assets/info-new.svg"
                  alt=""
                  @mouseenter="tooltip($event, weaknessTitle, 'in')"
                  @mouseleave="tooltip($event, weaknessTitle, 'out')"
                  @mousemove="tooltip($event, weaknessTitle, 'in')"
                  class="pl-2" />
              </td>
            </tr>
          </div>
          <div class="Rtable-cell background chart-container gauge-c">
            <GaugeChart
              v-if="
                product1Weaknesses &&
                product1Weaknesses.length &&
                !overallNoData
              "
              :data="product1Weaknesses"
              :chartNumber="3"
              :key="product1Weaknesses.map((d) => d.score).join(',')" />
            <div class="not-found" v-else>
              <no-data-container-vue />
            </div>
          </div>
          <div class="Rtable-cell"></div>
          <div class="Rtable-cell background chart-container gauge-c">
            <GaugeChart
              v-if="
                product2Weaknesses &&
                product2Weaknesses.length &&
                !overallNoData
              "
              :data="product2Weaknesses"
              :chartNumber="4"
              :key="product2Weaknesses.map((d) => d.score).join(',')" />
            <div class="not-found" v-else>
              <no-data-container-vue />
            </div>
          </div>
        </div>

        <div class="Rtable-row">
          <div class="Rtable-cell row-title">
            <tr>
              <td>Word Cloud:</td>
              <td></td>
            </tr>
          </div>
          <div class="Rtable-cell background chart-container container-big">
            <Wordcloud
              :cloudId="'word-cloud2'"
              :wordData="wordDataLeft"
              :chartHeight="145"
              :type="chartType"
              :maxFont="parseInt(30)"
              v-if="
                (product1Strengths.length || product1Weaknesses.length) &&
                wordDataLeft.length &&
                !overallNoData
              " />
            <div class="not-found" v-else>
              <no-data-container-vue />
            </div>
          </div>
          <div class="Rtable-cell"></div>
          <div class="Rtable-cell background chart-container">
            <Wordcloud
              :cloudId="'word-cloud3'"
              :wordData="wordDataRight"
              :type="chartType"
              :chartHeight="145"
              :maxFont="parseInt(30)"
              v-if="
                (product2Strengths.length || product2Weaknesses.length) &&
                wordDataRight.length &&
                !overallNoData
              " />
            <div class="not-found" v-else>
              <no-data-container-vue />
            </div>
          </div>
        </div>

        <div class="Rtable-row">
          <div class="Rtable-cell row-title">
            <tr>
              <td>Vantage Capabilities: (Net Sentiment):</td>
              <td>
                <img
                  src="../../assets/info-new.svg"
                  alt=""
                  @mouseenter="tooltip($event, vantageTitle, 'in')"
                  @mouseleave="tooltip($event, vantageTitle, 'out')"
                  @mousemove="tooltip($event, vantageTitle, 'in')"
                  class="pl-2" />
              </td>
            </tr>
          </div>
          <div class="Rtable-cell background chart-container vantage-container">
            <VantageCapabilities
              :data="vantagePointsDataLeft"
              v-if="vantagePointsDataLeft.length && !overallNoData" />
            <div class="not-found" v-else>
              <no-data-container-vue />
            </div>
          </div>
          <div class="Rtable-cell"></div>
          <div class="Rtable-cell background chart-container vantage-container">
            <VantageCapabilities
              :data="vantagePointsDataRight"
              v-if="vantagePointsDataRight.length && !overallNoData" />
            <div class="not-found" v-else>
              <no-data-container-vue />
            </div>
          </div>
        </div>

        <div class="Rtable-row empty-big">
          <div class="Rtable-cell"></div>
          <div class="Rtable-cell"></div>
          <div class="Rtable-cell"></div>
          <div class="Rtable-cell"></div>
        </div>

        <div class="Rtable-row">
          <div class="Rtable-cell row-title">
            <tr>
              <td>Capabilities Sentiment Score:</td>
              <td></td>
            </tr>
          </div>
          <div
            style="order: 3"
            class="Rtable-cell span-cell background bordered radar-cell">
            <div class="radar-container">
              <tr>
                <td class="radar" width="50%">
                  <div class="radar-heading">Standard capabilities</div>
                  <Radar
                    v-if="
                      (standardRadarData[0].length ||
                        standardRadarData[1].length) &&
                      !overallNoData
                    "
                    :radarData="standardRadarData"
                    radarId="radar1" />
                  <div class="not-found" v-else>
                    <no-data-container-vue />
                  </div>
                </td>
                <td class="radar" width="50%">
                  <div class="radar-heading">Functional capabilities</div>
                  <Radar
                    v-if="
                      (functionalRadarData[0].length ||
                        functionalRadarData[1].length) &&
                      !overallNoData
                    "
                    :radarData="functionalRadarData"
                    radarId="radar2" />
                  <div class="not-found" v-else>
                    <no-data-container-vue />
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div class="wordcloud-legend">
                    <div class="legend">
                      <span
                        class="colored-rect"
                        style="background: var(--radar-primary)"></span>
                      <p>{{ wlSelectedProductLeft.name }}</p>
                    </div>
                    <div class="legend">
                      <span
                        class="colored-rect"
                        style="background: var(--radar-secondary)"></span>
                      <p>{{ wlSelectedProductRight.name }}</p>
                    </div>
                  </div>
                </td>
              </tr>
            </div>
          </div>
        </div>

        <div class="Rtable-row">
          <div class="Rtable-cell row-title">
            <tr>
              <td>Competitive Positioning:</td>
              <td>
                <img
                  src="../../assets/info-new.svg"
                  alt=""
                  @mouseenter="tooltip($event, competitionTitle, 'in')"
                  @mouseleave="tooltip($event, competitionTitle, 'out')"
                  @mousemove="tooltip($event, competitionTitle, 'in')"
                  class="pl-2" />
              </td>
            </tr>
          </div>
          <div
            style="order: 3"
            class="Rtable-cell span-cell background bordered">
            <div
              v-if="bubbleData.length && !overallNoData"
              class="bubble-chart-pseudo">
              <bubble-chart-vue
                :noData="false"
                :data="bubbleData"
                :hideHeader="true"
                :legendsText="bubbleLegends"
                :domains="bubbleChartDomains"
                :categoryAvg="categoryAvg"
                :selectiveColoring="true"
                tooltipType="winloss" />
              <div class="bubble-legend">
                <div class="legend">
                  <span
                    class="colored-rect"
                    style="background: var(--radar-primary)"></span>
                  <p>{{ wlSelectedProductLeft.name }}</p>
                </div>
                <div class="legend">
                  <span
                    class="colored-rect"
                    style="background: var(--radar-secondary)"></span>
                  <p>{{ wlSelectedProductRight.name }}</p>
                </div>
                <div class="legend">
                  <span class="colored-rect" style="background: #818488"></span>
                  <p>Others</p>
                </div>
              </div>
            </div>
            <div class="not-found" v-else>
              <no-data-container-vue />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SingleSelectorVue from '../../components/Dropdowns/SingleSelector.vue'
import Wordcloud from '../../components/SunriseSunset/WordCloud'
import GaugeChart from '../../components/WinLoss/GaugeChart'
import Radar from '../../components/WinLoss/Radar'
import RadialProgress from '../../components/WinLoss/RadialProgress'
import VantageCapabilities from '../../components/WinLoss/VantageCapabilities'
import BubbleChartVue from '../../components/Report/BubbleChart.vue'
import NoDataContainerVue from '../../components/Common/NoDataContainer/NoDataContainer.vue'
import tooltip from '../../util/tooltip'
import { metaTitle } from '../../constant/data'
export default {
  name: 'winloss',
  metaInfo () {
    return {
      title: metaTitle.winloss
    }
  },
  components: {
    Wordcloud,
    VantageCapabilities,
    Radar,
    // RadialProgress,
    SingleSelectorVue,
    GaugeChart,
    BubbleChartVue,
    NoDataContainerVue
  },
  data () {
    return {
      noData: false,
      chartType: 'strength',
      cssVars: {
        '--cellWidth': '30vw'
      },
      bubbleLegends: {
        x: 'Functional capabilities satisfaction',
        y: 'Standard capabilities satisfaction'
      },
      strengthTitle:
        'Shows the top three capabilities for the\nproduct with the highest sentiment score',
      weaknessTitle:
        'Shows the bottom three capabilities for the\nproduct with the lowest sentiment score',
      vantageTitle:
        'Shows the top capabilities on which\nthe product scores better than the\nother product selected',
      competitionTitle:
        'Shows how the products fare in\nterms of sentiment score on\nfunctional and standard capabilities\nthat constitute the CSAT drivers',
      wordDataLeft: [],
      wordDataRight: [],
      bubbleChartDomains: {
        x: [-5, 5],
        y: [-5, 5]
      },
      overallNoData: false
      // bubbleData: [
      //   {
      //     product_name: 'AppDynamics',
      //     x: 2,
      //     volume: 5,
      //     y: 2
      //   },
      //   {
      //     product_name: 'Dynatrace',
      //     x: 3,
      //     volume: 5,
      //     y: 1
      //   }
      // ]
    }
  },
  computed: {
    ...mapState('winLoss', [
      'wlSelectedProductLeft',
      'wlSelectedProductRight',
      'wlLeftProductData',
      'wlRightProductData',
      'attributeDataLeft',
      'attributeDataRight',
      'competitivePositioning',
      'categoryAvg'
    ]),
    ...mapState('filters', [
      'productList',
      'features',
      'selectedSourceList',
      'selectedCategories',
      'selectedProducts',
      'sortedProducts',
      'selectedIndustries',
      'selectedCompanies',
      'subCategories',
      'ActiveVocTimePeriod',
      'previousPeriodRange',
      'isPreviousPeriodInSubscription'
    ]),
    chartContainerWidth () {
      return '450px'
    },
    product1Strengths () {
      if (this.wlLeftProductData.length) {
        return this.wlLeftProductData
          .filter((d, i) => {
            return parseFloat(d.strength) > 0 && i < 3
          })
          .map((d) => {
            return {
              topic: d.name,
              score: d.strength,
              id: d.id,
              is_standard: d.is_standard,
              parent_id: d.parent_id
            }
          })
      } else {
        return []
      }
    },
    product1Weaknesses () {
      if (this.wlLeftProductData.length) {
        const strengthIds = this.product1Strengths.map((s) => s.id)
        const nonStrengths = this.wlLeftProductData
          .filter((t) => !strengthIds.includes(t.id))
          .reverse()
        return nonStrengths.splice(0, 3).map((d) => {
          return {
            topic: d.name,
            score: d.strength,
            id: d.id,
            is_standard: d.is_standard,
            parent_id: d.parent_id
          }
        })
      } else {
        return []
      }
    },
    product2Strengths () {
      if (this.wlRightProductData.length) {
        return this.wlRightProductData
          .filter((d, i) => {
            return parseFloat(d.strength) > 0 && i < 3
          })
          .map((d) => {
            return {
              topic: d.name,
              score: d.strength,
              id: d.id,
              is_standard: d.is_standard,
              parent_id: d.parent_id
            }
          })
      } else {
        return []
      }
    },
    product2Weaknesses () {
      if (this.wlRightProductData.length) {
        const strengthIds = this.product2Strengths.map((s) => s.id)
        const nonStrengths = this.wlRightProductData
          .filter((t) => !strengthIds.includes(t.id))
          .reverse()
        return nonStrengths.splice(0, 3).map((d) => {
          return {
            topic: d.name,
            score: d.strength,
            id: d.id,
            is_standard: d.is_standard,
            parent_id: d.parent_id
          }
        })
      } else {
        return []
      }
    },
    vantagePointsDataLeft () {
      if (this.wlLeftProductData.length) {
        const tempVantage = []
        this.wlLeftProductData.forEach((dl) => {
          // let match = this.wlRightProductData.filter((dr) => {
          //   return dl.id === dr.id
          // })[0]
          const match = this.wlRightProductData.find((dr) => {
            return dl.id === dr.id
          })
          if (match) {
            if (parseFloat(dl.strength) > parseFloat(match.strength)) {
              const obj = {
                name: dl.name,
                delta: (dl.strength - match.strength).toFixed(2)
              }
              tempVantage.push(obj)
            }
          }
        })
        return tempVantage
          .sort(function (a, b) {
            return b.delta - a.delta
          })
          .splice(0, 3)
      } else {
        return []
      }
    },
    vantagePointsDataRight () {
      if (this.wlRightProductData.length) {
        const tempVantage = []
        this.wlRightProductData.forEach((dl) => {
          // let match = this.wlLeftProductData.filter((dr) => {
          //   return dl.id === dr.id
          // })[0]
          const match = this.wlLeftProductData.find((dr) => {
            return dl.id === dr.id
          })
          if (match) {
            if (parseFloat(dl.strength) > parseFloat(match.strength)) {
              const obj = {
                name: dl.name,
                delta: (dl.strength - match.strength).toFixed(2)
              }
              tempVantage.push(obj)
            }
          }
        })
        return tempVantage
          .sort(function (a, b) {
            return b.delta - a.delta
          })
          .splice(0, 3)
      } else {
        return []
      }
    },
    standardRadarData () {
      const standardDataLeft = this.wlLeftProductData
        .filter((t) => t.is_standard === true)
        .map((t) => {
          return {
            area: t.name,
            value: t.strength,
            productName: this.wlSelectedProductLeft.name
          }
        })
        .sort((a, b) => {
          const fa = a.area.toLowerCase()
          const fb = b.area.toLowerCase()

          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
      const standardDataRight = this.wlRightProductData
        .filter((t) => t.is_standard === true)
        .map((t) => {
          return {
            area: t.name,
            value: t.strength,
            productName: this.wlSelectedProductRight.name
          }
        })
        .sort((a, b) => {
          const fa = a.area.toLowerCase()
          const fb = b.area.toLowerCase()

          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
      return [standardDataLeft, standardDataRight]
    },
    functionalRadarData () {
      const functionalDataLeft = this.wlLeftProductData
        .filter((t) => t.is_standard === false)
        .map((t) => {
          return {
            area: t.name,
            value: t.strength,
            productName: this.wlSelectedProductLeft.name
          }
        })
        .sort((a, b) => {
          const fa = a.area.toLowerCase()
          const fb = b.area.toLowerCase()

          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
      const functionalDataRight = this.wlRightProductData
        .filter((t) => t.is_standard === false)
        .map((t) => {
          return {
            area: t.name,
            value: t.strength,
            productName: this.wlSelectedProductRight.name
          }
        })
        .sort((a, b) => {
          const fa = a.area.toLowerCase()
          const fb = b.area.toLowerCase()

          if (fa < fb) {
            return -1
          }
          if (fa > fb) {
            return 1
          }
          return 0
        })
      return [functionalDataLeft, functionalDataRight]
    },
    bubbleData () {
      return this.competitivePositioning.map((d) => {
        return {
          product_name: d.product_name,
          x: d.functionalStrength,
          y: d.standardStrength,
          volume: d.overall_rating
        }
      })
    },
    watchable () {
      return JSON.stringify(this.selectedSourceList) + JSON.stringify(this.selectedIndustries) + JSON.stringify(this.selectedCompanies) + JSON.stringify(this.selectedProducts) + JSON.stringify(this.ActiveVocTimePeriod)
    }
  },
  watch: {
    watchable () {
      if (this.watchable.length) {
        this.getLeftData()
        this.getRightData()
      }
    },
    product1Strengths () {
      this.updateWlLeftStrengths(this.product1Strengths)
    },
    product1Weaknesses () {
      this.updateWlLeftWeaknesses(this.product1Weaknesses)
    },
    product2Strengths () {
      this.updateWlRightStrengths(this.product2Strengths)
    },
    product2Weaknesses () {
      this.updateWlRightWeaknesses(this.product2Weaknesses)
    },
    wlLeftProductData () {
      this.getRelativeDataLeft()
    },
    wlRightProductData () {
      this.getRelativeDataRight()
    },
    attributeDataLeft () {
      this.wordDataLeft = this.attributeDataLeft
    },
    attributeDataRight () {
      this.wordDataRight = this.attributeDataRight
    },
    subCategories () {
      if (this.subCategories.length) {
        this.getLocalData()
      }
    },
    wlSelectedProductLeft () {
      this.getLeftData()
    },
    wlSelectedProductRight () {
      this.getRightData()
    },
    selectedCategories () {
      this.updateWlSelectedLeft([])
      this.updateWlSelectedRight([])
    }
    // selectedSourceList () {
    //   this.getLeftData()
    //   this.getRightData()
    // },
    // selectedIndustries () {
    //   this.getLeftData()
    //   this.getRightData()
    // },
    // selectedCompanies () {
    //   this.getLeftData()
    //   this.getRightData()
    // },
    // selectedProducts () {
    //   this.getLeftData()
    //   this.getRightData()
    // },
    // ActiveVocTimePeriod () {
    //   this.getLeftData()
    //   this.getRightData()
    // }
  },
  mounted () {
    this.getLeftData()
    this.getRightData()
    document.getElementsByClassName('padding-both-side')[0].style.paddingRight =
      '2px'
    setTimeout(() => {
      const width = 200
      this.cssVars = {
        '--cellWidth': width + 'px'
      }
    }, 10)
  },
  methods: {
    ...mapActions('filters', [
      'getAllLocalFiltersData',
      'updateSelectedProducts',
      'updateSortedProducts'
      // 'features'
    ]),
    ...mapActions('winLoss', [
      'updateWlSelectedLeft',
      'updateWlSelectedRight',
      'getLeftWinLossData',
      'getRightWinLossData',
      'getAttributeDataLeft',
      'getAttributeDataRight',
      'getCompetitivePositioning',
      'updateWlLeftStrengths',
      'updateWlLeftWeaknesses',
      'updateWlRightStrengths',
      'updateWlRightWeaknesses'
    ]),
    tooltip,
    onChangeProductLeft (data) {
      this.updateWlSelectedLeft(data)
    },
    onChangeProductRight (data) {
      this.updateWlSelectedRight(data)
    },
    HandelDropdownToggle (side, action) {
      let rowColumns = document.querySelectorAll('.empty')
      rowColumns = [...rowColumns]
      let cell = null
      if (side === 'left') {
        cell = rowColumns[1]
      }
      if (side === 'right') {
        cell = rowColumns[3]
      }
      if (action === 'open') {
        cell.style.backgroundColor = 'transparent'
      }
      if (action === 'close') {
        cell.style.backgroundColor = 'var(--background-color)'
      }
    },
    getLocalData () {
      this.getAllLocalFiltersData({
        categoryId: this.subCategories,
        subscriptionId: this.selectedCategories.subscriptionId
      })
    },
    getLeftData () {
      if (
        this.selectedSourceList.length &&
        // this.selectedProducts.length &&
        this.wlSelectedProductLeft &&
        this.selectedIndustries.length &&
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod
        // this.features
      ) {
        this.overallNoData = false
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          products: [this.wlSelectedProductLeft],
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          industries: this.selectedIndustries.map((item) => item.id),
          companySize: this.selectedCompanies.map((item) => item.id),
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId
        }
        // if (this.selectedSSTopic) {
        //   data.selectedSSTopic = this.selectedSSTopic
        //   // data.is_standard = this.is_SSTstandard
        //   data.is_standard = this.selectedSSTopic.is_standard
        // }
        this.getLeftWinLossData(data)
        this.competitivePositioningData()
      } else {
        this.noData = true
        this.overallNoData = true
      }
      // this.checkDataSelection()
    },
    getRightData () {
      if (
        this.selectedSourceList.length &&
        // this.selectedProducts.length &&
        this.wlSelectedProductRight &&
        this.selectedIndustries.length &&
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod
        // this.features
      ) {
        this.overallNoData = false
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          products: [this.wlSelectedProductRight],
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          industries: this.selectedIndustries.map((item) => item.id),
          companySize: this.selectedCompanies.map((item) => item.id),
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId
        }
        // if (this.selectedSSTopic) {
        //   data.selectedSSTopic = this.selectedSSTopic
        //   // data.is_standard = this.is_SSTstandard
        //   data.is_standard = this.selectedSSTopic.is_standard
        // }
        this.getRightWinLossData(data)
        this.competitivePositioningData()
      } else {
        this.noData = true
        this.overallNoData = true
      }
      // this.checkDataSelection()
    },
    getRelativeDataLeft () {
      // console.log(this.product1Strengths)
      if (
        this.selectedSourceList.length &&
        this.wlSelectedProductLeft &&
        this.selectedIndustries.length &&
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        // this.features.length &&
        (this.product1Strengths || this.product1Weaknesses)
      ) {
        this.overallNoData = false
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          products: [this.wlSelectedProductLeft],
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          industries: this.selectedIndustries.map((item) => item.id),
          companySize: this.selectedCompanies.map((item) => item.id),
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId,
          allTopics: [...this.product1Strengths, ...this.product1Weaknesses],
          strenghts: this.product1Strengths,
          weaknesses: this.product1Weaknesses
        }
        this.getAttributeDataLeft(data)
      } else {
        this.noData = true
        this.overallNoData = true
      }
      // this.checkDataSelection()
    },
    getRelativeDataRight () {
      // console.log(this.product1Strengths)
      if (
        this.selectedSourceList.length &&
        this.wlSelectedProductRight &&
        this.selectedIndustries.length &&
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        // this.features.length &&
        (this.product2Strengths || this.product2Weaknesses)
      ) {
        this.overallNoData = false
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          products: [this.wlSelectedProductRight],
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          industries: this.selectedIndustries.map((item) => item.id),
          companySize: this.selectedCompanies.map((item) => item.id),
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId,
          allTopics: [...this.product2Strengths, ...this.product2Weaknesses],
          strenghts: this.product2Strengths,
          weaknesses: this.product2Weaknesses
        }
        this.getAttributeDataRight(data)
      } else {
        this.noData = true
        this.overallNoData = true
      }
      // this.checkDataSelection()
    },
    competitivePositioningData () {
      if (
        this.selectedSourceList.length &&
        this.wlSelectedProductRight &&
        this.selectedIndustries.length &&
        this.selectedCategories != null &&
        this.subCategories.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod
      ) {
        this.overallNoData = false
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          products: this.selectedProducts,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          industries: this.selectedIndustries.map((item) => item.id),
          companySize: this.selectedCompanies.map((item) => item.id),
          categoryId: this.subCategories,
          subscriptionId: this.selectedCategories.subscriptionId
        }
        this.getCompetitivePositioning(data)
      } else {
        this.noData = true
        this.overallNoData = true
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  beforeDestroy () {
    document.getElementsByClassName('padding-both-side')[0].style.paddingRight =
      '20px'
  }
}
</script>

<style lang="scss" src="./winloss.scss" scoped />
