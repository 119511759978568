<template>
  <div class="rp-parent">
  <svg :id="rpId"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'

/**
 * This component renders the Radial Progress chart found on the **Competitive Positioning** page.
 */
export default {
  name: 'RadialProgress',
  props: {
    rpId: {
      type: String,
      default: null
    },
    progressPercent: {
      type: Number,
      default: null
    },
    productName: {
      type: String,
      default: null
    }
  },
  data() {
    return {}
  },
  watch: {
    progressPercent() {
      if (this.productName && this.progressPercent !== null) {
        this.renderChart()
      }
    },
    productName() {
      if (this.productName && this.progressPercent !== null) {
        this.renderChart()
      }
    }
  },
  mounted() {
    this.resizeWindow()
  },
  methods: {
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (d3.select('.rp-parent').node() != null) {
          this.renderChart()
        }
      })
      this.observer.observe(document.querySelector('.rp-parent'))
    },
    /**
     * This method uses the D3 library to render the chart
     */
    renderChart() {
      // const parent = d3.select(selector)
      d3.selectAll('#' + this.rpId + ' > *').remove()

      const size = {
        width: 100,
        height: 100
      } // parent.node().getBoundingClientRect()
      const svg = d3
        .select('#' + this.rpId) // parent.append('svg')
        .attr('width', size.width)
        .attr('height', size.height)
      const outerRadius = Math.min(size.width, size.height) * 0.45
      const thickness = 5
      let value = 0

      const mainArc = d3
        .arc()
        .startAngle(0)
        .endAngle(Math.PI * 2)
        .innerRadius(outerRadius - thickness)
        .outerRadius(outerRadius)

      svg
        .append('path')
        .attr('class', 'progress-bar-bg')
        .attr('transform', `translate(${size.width / 2},${size.height / 2})`)
        .attr('d', mainArc())
        .attr('fill', 'var(--main-arc-fill)')

      const mainArcPath = svg
        .append('path')
        .attr('class', 'progress-bar')
        .attr('transform', `translate(${size.width / 2},${size.height / 2})`)
        .attr('fill', 'var(--word-cloud-emerging-color)')

      svg
        .append('circle')
        .attr('class', 'progress-bar')
        .attr(
          'transform',
          `translate(${size.width / 2},${
            size.height / 2 - outerRadius + thickness / 2
          })`
        )
        .attr('width', thickness)
        .attr('height', thickness)
        .attr('r', thickness / 2)
        .attr('fill', 'var(--word-cloud-emerging-color)')

      const end = svg
        .append('circle')
        .attr('class', 'progress-bar')
        .attr(
          'transform',
          `translate(${size.width / 2},${
            size.height / 2 - outerRadius + thickness / 2
          })`
        )
        .attr('width', thickness)
        .attr('height', thickness)
        .attr('r', thickness / 2)
        .attr('fill', 'var(--word-cloud-emerging-color)')

      let progressPercent = this.progressPercent
      const startValue = value
      const startAngle = (Math.PI * startValue) / 50
      const angleDiff = (Math.PI * progressPercent) / 50 - startAngle
      const startAngleDeg = (startAngle / Math.PI) * 180
      const angleDiffDeg = (angleDiff / Math.PI) * 180
      const transitionDuration = 100
      const productName = this.productName

      var anchor = { w: size.width / 3, h: size.height / 3 }

      let texts = svg
        .append('foreignObject')
        .attr('class', 'fo')
        .attr('width', '100px')
        .attr('height', '100px')
      var div = texts.append('xhtml:div').append('div')
      div
        .style('display', 'flex')
        .style('align-items', 'center')
        .style('justify-content', 'middle')
        .style('width', '100px')
        .style('height', '100px')
      let percentLabel = div
        .append('p')
        .style('width', '70px')
        .style('max-height', '40px')
        .attr('class', 'progress-label')
        .style('text-align', 'center')
        .style('margin-left', '14px')
        .style('margin-right', '13px')

      percentLabel
        .append('p')
        .html(productName)
        .style('font-size', '12px')
        .style('line-height', '14px')
        .style('font-family', 'roboto')
        .style('color', 'var(--word-cloud-text-color)')
        .style('width', '100%')
        .style('white-space', 'initial')
        .style('display', '-webkit-box')
        .style('-webkit-line-clamp', '2')
        .style('-webkit-box-orient', 'vertical')
        .style('text-overflow', 'ellipsis')
        .style('overflow', 'hidden')
        .style('-webkit-hyphens', 'auto')
        .style('-moz-hyphens', 'auto')
        .style('-ms-hyphens', 'auto')
        .style('word-wrap', 'break-word')
        .style('overflow-wrap', 'break-word')
// -webkit-hyphens: auto;
//   -moz-hyphens: auto;
//   -ms-hyphens: auto;
//   hyphens: auto;
      percentLabel
        .append('p')
        .attr('class', 'progress-label2')
        .html(progressPercent + '%')
        .style('font-size', '14px')
        .style('font-family', 'roboto')
        .style('color', 'var(--fifth-text-color)')

      // let percentLabel = texts
      //   .append('text')
      //   .attr("dy", "-0.2em")
      //   .style("text-anchor", "middle")
      //   .attr('class', 'progress-label')
      //   .text(productName)
      //   .attr('font-size', '12px')
      //   .attr('font-family', 'roboto')
      //   .attr('fill', 'var(--word-cloud-text-color)')

      // let percentLabel2 = texts
      //   .append('text')
      //   .attr("dy", "1.0em")
      //   .style("text-anchor", "middle")
      //   .attr('class', 'progress-label2')
      //   .text(progressPercent + '%')
      //   .attr('font-size', '14px')
      //   .attr('font-family', 'roboto')
      //   .attr('fill', 'var(--fifth-text-color)')

      mainArcPath
        .transition()
        .duration(transitionDuration)
        .attrTween('d', function () {
          return function (t) {
            mainArc.endAngle(startAngle + angleDiff * t)
            return mainArc()
          }
        })
      end
        .transition()
        .duration(transitionDuration)
        .attrTween('transform', function () {
          return function (t) {
            return (
              `translate(${size.width / 2},${size.height / 2})` +
              `rotate(${startAngleDeg + angleDiffDeg * t})` +
              `translate(0,-${outerRadius - thickness / 2})`
            )
          }
        })

      // percentLabel.transition().duration(transitionDuration).tween('bla', function() {
      //   return function(t) {
      //     percentLabel.text(Math.round(startValue + (progressPercent - startValue) * t));
      //   }
      // })
      // value = progressPercent
      //   }
      // }
    }

  }
}
</script>

<style lang="scss" scoped>

.rp-parent {
  display: flex;
}
</style>
