<template>
  <div class="vantage">
    <tr>
      <!-- <td>{{topic.name}} -</td> -->
      <td v-for="topic in data" :key="topic.id" class="vantage-score">+{{topic.delta}}</td>
    </tr>
    <tr>
      <td v-for="topic in data" :key="topic.id" class="vantage-name">{{topic.name}}</td>
      <!-- <td>+{{topic.delta}}</td> -->
    </tr>
  </div>
</template>

<script>
/**
 * This component renders the Vantage Capabilities data in the comparison table on the **Competitive Positioning** page.
 */
export default {
  name: 'VantageCapabilities',
  props: {
    /**
     * The data needed to render the component.
     */
    data: {
      type: Array
    }
  },
  data () {
    return {}
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.vantage {
  display: table;
  // margin: 10px;
  width: 100%;
  margin: 20px 0 30px;
  tr {
    width: 100%;
    td {
      width: 33.33%;
      text-align: center;
      color: var(--fifth-text-color);
      font-size: 12px;
      line-height: 22px;
    }
  }
  .vantage-score {
    color: var(--vantage-score);
    font-size: 34px;
    font-weight: 600;
    padding: 20px 0 15px;
  }
  .vantage-name {
    font-size: 18px;
    font-weight: 500;
  }
}

@media screen and (max-width: 1800px) {
  .vantage {
    .vantage-score {
      font-size: 28px;
    }
    .vantage-name {
      font-size: 14px;
    }
  }
}
</style>
