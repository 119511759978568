<template>
  <div class="radar-parent">
    <svg :id="radarId"></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
import tooltip from '../../util/tooltip'

/**
 * This component renders the Radar/Spider charts found on the **Competitive Positioning** page.
 */
export default {
  name: 'Radar',
  props: {
    /**
     * The id for the chart
     */
    radarId: {
      type: String,
      default: null
    },
    /**
     * The data required for the chart to render
     */
    radarData: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      // radarData: [
      //   [
      //     { area: 'Central ', value: 80 },
      //     { area: 'Kirkdale', value: 40 },
      //     { area: 'Drake ', value: 40 },
      //     { area: 'Everton ', value: 90 },
      //     { area: 'Picton ', value: 60 },
      //     { area: 'Riverside ', value: 80 },
      //     { area: 'king', value: 80 }
      //   ],
      //   [
      //     { area: 'Central ', value: 10 },
      //     { area: 'Drake', value: 20 },
      //     { area: 'Kensington ', value: 60 },
      //     { area: 'Everton ', value: 30 },
      //     { area: 'Picton ', value: 70 },
      //     { area: 'Riverside ', value: 50 },
      //     { area: 'king', value: 50 }
      //   ]
      // ]
    }
  },
  watch: {
    radarData () {
      this.renderChart()
    }
  },
  mounted () {
    this.resizeWindow()
  },
  methods: {
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.radarData.length) {
          if (d3.select('.radar-parent').node() != null) {
            // console.log('Resizing')
            this.renderChart()
          }
        }
      })
      this.observer.observe(document.querySelector('.radar-parent'))
    },
    /**
     * This method is used to render the chart
     */
    renderChart () {
      const that = this
      d3.selectAll('#' + this.radarId + ' > *').remove()

      const data = this.radarData

      const margin = { top: 10, right: 10, bottom: 0, left: 10 }
      // const width = 210
      let width =
        d3.select('.radar-cell').node().getBoundingClientRect().width / 3 -
        margin.left -
        margin.right -
        40
      if (width > 210) {
        width = 210
      }
      const height = width // 200
      // margin.top - margin.bottom
      // Config for the Radar chart
      const config = {
        w: width,
        h: height,
        maxValue: 100,
        levels: 5,
        ExtraWidthX: 140 // 300
      }

      const svg = d3
        .select('#' + this.radarId)
        .attr('width', width)
        .attr('height', height)
      // .append('g')
      // .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

      // d3.selectAll('#' + this.radarId + ' > *').remove()

      const RadarChart = {
        draw: function (id, d, options) {
          const cfg = {
            radius: 2,
            w: width,
            h: height,
            factor: 1,
            factorLegend: 0.75,
            wrapWidth: 60,
            levels: 3,
            maxValue: 0,
            radians: 2 * Math.PI,
            opacityArea: 0.4,
            ToRight: 5,
            TranslateX: 70,
            TranslateY: 60,
            ExtraWidthX: 140,
            ExtraWidthY: 100,
            color: d3
              .scaleOrdinal()
              .range([
                'var(--radar-primary)',
                'var(--radar-secondary)'
              ])
          }

          if (typeof options !== 'undefined') {
            for (const i in options) {
              if (typeof options[i] !== 'undefined') {
                cfg[i] = options[i]
              }
            }
          }

          cfg.maxValue = d3.max([
            ...that.radarData[0].map((d) => parseFloat(d.value)),
            ...that.radarData[1].map((d) => parseFloat(d.value))
          ])

          const allAxis = d[0].map(function (i, j) {
            return i.area
          })
          const total = allAxis.length
          const radius = cfg.factor * Math.min(cfg.w / 2, cfg.h / 2)
          const Format = d3.format('%')
          // d3.select(id).remove();

          const g = d3
            .select('#' + that.radarId)
            .attr('width', cfg.w + cfg.ExtraWidthX)
            .attr('height', cfg.h + cfg.ExtraWidthY)
            .append('g')
            .attr(
              'transform',
              'translate(' + cfg.TranslateX + ',' + cfg.TranslateY + ')'
            )

          function tooltipFun (event, d, type) {
            let data = {}
            switch (type) {
              case 'in':
                data = {
                  '': d.productName,
                  'Capability': d.area,
                  'Sentiment score': d.value === null ? 'Not available' : d.value
                }
                break
            }
            tooltip(event, data, type)
          }

          // Circular segments
          for (let j = 0; j < cfg.levels; j++) {
            var levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels)
            g.selectAll('.levels')
              .data(allAxis)
              .enter()
              .append('svg:line')
              .attr('x1', function (d, i) {
                return (
                  levelFactor *
                  (1 - cfg.factor * Math.sin((i * cfg.radians) / total))
                )
              })
              .attr('y1', function (d, i) {
                return (
                  levelFactor *
                  (1 - cfg.factor * Math.cos((i * cfg.radians) / total))
                )
              })
              .attr('x2', function (d, i) {
                return (
                  levelFactor *
                  (1 - cfg.factor * Math.sin(((i + 1) * cfg.radians) / total))
                )
              })
              .attr('y2', function (d, i) {
                return (
                  levelFactor *
                  (1 - cfg.factor * Math.cos(((i + 1) * cfg.radians) / total))
                )
              })
              .attr('class', 'line')
              .style('stroke', 'grey')
              .style('stroke-opacity', '0.75')
              .style('stroke-width', '0.3px')
              .attr(
                'transform',
                'translate(' +
                  (cfg.w / 2 - levelFactor) +
                  ', ' +
                  (cfg.h / 2 - levelFactor) +
                  ')'
              )
          }

          let series = 0

          const axis = g
            .selectAll('.axis')
            .data(allAxis)
            .enter()
            .append('g')
            .attr('class', 'axis')

          axis
            .append('line')
            .attr('x1', cfg.w / 2)
            .attr('y1', cfg.h / 2)
            .attr('x2', function (d, i) {
              return (
                (cfg.w / 2) *
                (1 - cfg.factor * Math.sin((i * cfg.radians) / total))
              )
            })
            .attr('y2', function (d, i) {
              return (
                (cfg.h / 2) *
                (1 - cfg.factor * Math.cos((i * cfg.radians) / total))
              )
            })
            .attr('class', 'line')
            .style('stroke', 'grey')
            .style('stroke-opacity', '0.5')
            .style('stroke-width', '0.3px')

          // axis
          //   .append('text')
          //   .attr('class', 'legend')
          //   .attr('fill', 'var(--fifth-text-color)')
          //   .text(function (d) {
          //     return d
          //   })
          //   .style('font-family', 'roboto')
          //   .style('font-size', '11px')
          //   .attr('text-anchor', 'middle')
          //   .attr('dy', '1.25em')
          //   .attr('transform', function (d, i) {
          //     return 'translate(0, -10)'
          //   })
          //   .attr('x', function (d, i) {
          //     return (
          //       (cfg.w / 2) *
          //         (1 - cfg.factorLegend * Math.sin((i * cfg.radians) / total)) -
          //       60 * Math.sin((i * cfg.radians) / total)
          //     )
          //   })
          //   .attr('y', function (d, i) {
          //     return (
          //       (cfg.h / 2) * (1 - Math.cos((i * cfg.radians) / total)) -
          //       20 * Math.cos((i * cfg.radians) / total)
          //     )
          //   })
          // .call(wrap, cfg.wrapWidth)

          axis
            .append('foreignObject')
            .attr('class', 'fo')
            .attr('width', '70px')
            .attr('height', '30px')
            // .attr('dy', '1.25em')
            .attr('transform', function (d, i) {
              return 'translate(-30, -15)'
            })
            .attr('x', function (d, i) {
              return (
                (cfg.w / 2) *
                (1 - cfg.factorLegend * Math.sin((i * cfg.radians) / total)) -
                70 * Math.sin((i * cfg.radians) / total)
              )
            })
            .attr('y', function (d, i) {
              return (
                (cfg.h / 2) * (1 - Math.cos((i * cfg.radians) / total)) -
                20 * Math.cos((i * cfg.radians) / total)
              )
            })
            .append('xhtml:div')
            .append('div')
            .style('display', 'flex')
            .style('align-items', 'center')
            .style('justify-content', 'middle')
            .style('width', '70px')
            .style('height', '30px')
            .append('p')
            .style('width', '70px')
            .style('max-height', '30px')
            .attr('class', 'progress-label')
            .style('text-align', 'center')
            .append('p')
            .html((d) => {
              return d
            })
            .style('font-size', '12px')
            .style('line-height', '14px')
            .style('font-family', 'Quicksand')
            .style('font-weight', 500)
            .style('color', 'var(--radar-text-color)')
            .style('width', '100%')
            .style('white-space', 'initial')
            .style('display', '-webkit-box')
            .style('-webkit-line-clamp', '2')
            .style('-webkit-box-orient', 'vertical')
            .style('text-overflow', 'ellipsis')
            .style('overflow', 'hidden')
            .style('-webkit-hyphens', 'auto')
            .style('-moz-hyphens', 'auto')
            .style('-ms-hyphens', 'auto')
            .style('word-wrap', 'break-word')
            .style('overflow-wrap', 'break-word')

          function wrap (text, width) {
            text.each(function () {
              const text = d3.select(this)
              const words = text.text().split(/\s+/).reverse()
              let word
              let line = []
              let lineNumber = 0
              const lineHeight = 1.4 // ems
              const y = text.attr('y')
              const x = text.attr('x')
              const dy = parseFloat(text.attr('dy')) - 0.5
              let tspan = text
                .text(null)
                .append('tspan')
                .attr('x', x)
                .attr('y', y)
                .attr('dy', dy + 'em')

              while ((word = words.pop())) {
                line.push(word)
                tspan.text(line.join(' '))
                if (tspan.node().getComputedTextLength() > width) {
                  line.pop()
                  tspan.text(line.join(' '))
                  line = [word]
                  tspan = text
                    .append('tspan')
                    .attr('x', x)
                    .attr('y', y)
                    .attr('dy', ++lineNumber * lineHeight + dy + 'em')
                    .text(word)
                }
              }
            })
          }

          let dataValues = []
          d.forEach(function (y, x) {
            dataValues = []
            g.selectAll('.nodes').data(y, function (j, i) {
              dataValues.push([
                (cfg.w / 2) *
                  (1 -
                    (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                    cfg.factor *
                    Math.sin((i * cfg.radians) / total)),
                (cfg.h / 2) *
                  (1 -
                    (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                    cfg.factor *
                    Math.cos((i * cfg.radians) / total))
              ])
            })
            dataValues.push(dataValues[0])
            g.selectAll('.area')
              .data([dataValues])
              .enter()
              .append('polygon')
              .attr('class', 'radar-chart-serie' + series)
              .style('stroke-width', '0px')
              .style('stroke', cfg.color(series))
              .attr('points', function (d) {
                const centerX = cfg.w / 2;
                const centerY = cfg.h / 2;
                let str = ''
                for (let pti = 0; pti < d.length; pti++) {
                  if (d && d[pti]) {
                    str = str + d[pti][0] + ',' + d[pti][1] + ' '
                  }
                }
                return str
              })
              .style('fill', function (j, i) {
                return cfg.color(series)
              })
              .style('fill-opacity', cfg.opacityArea)
              .on('mouseover', function (d) {
              })
              .on('mouseout', function () {
              })
            series++
          })

          series = 0

          d.forEach(function (y, x) {
            g.selectAll('.nodes')
              .data(y)
              .enter()
              .append('svg:circle')
              .attr('class', 'radar-chart-serie' + series)
              .attr('r', 5)
              .attr('alt', function (j) {
                return Math.max(j.value, 0)
              })
              .attr('cx', function (j, i) {
                dataValues.push([
                  (cfg.w / 2) *
                    (1 -
                      (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                      cfg.factor *
                      Math.sin((i * cfg.radians) / total)),
                  (cfg.h / 2) *
                    (1 -
                      (parseFloat(Math.max(j.value, 0)) / cfg.maxValue) *
                      cfg.factor *
                      Math.cos((i * cfg.radians) / total))
                ])
                let xValue = (
                  (cfg.w / 2) *
                  (1 -
                    (Math.max(j.value, 0) / cfg.maxValue) *
                    cfg.factor *
                    Math.sin((i * cfg.radians) / total))
                )
                return isNaN(xValue) ? cfg.w / 2 : xValue
              })
              .attr('cy', function (j, i) {
                let yValue = (
                  (cfg.h / 2) *
                  (1 -
                    (Math.max(j.value, 0) / cfg.maxValue) *
                    cfg.factor *
                    Math.cos((i * cfg.radians) / total))
                )
                return isNaN(yValue) ? cfg.h / 2 : yValue
              })
              .attr('data-id', function (j) {
                return j.area
              })
              .style('fill', cfg.color(series))
              .style('stroke-width', '0px')
              .style('stroke', cfg.color(series))
              .style('fill-opacity', 1)
              .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
              .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
              .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))

            series++
          })
        }
      }

      RadarChart.draw('#' + this.radarId, data, config)
    }
  }
}
</script>

<style>
.axis {
  font: 15px sans-serif;
}
.axis path,
.axis line {
  fill: none;
  stroke: #d4d8da;
  stroke-width: 2px;
  shape-rendering: crispEdges;
}
/* .radar-parent {
	position: absolute;
	top: 50px;
	left: 100px;
} */
</style>
