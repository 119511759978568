<template>
  <div :class="`chart-gauge chart-${chartNumber}`"></div>
</template>

<script>
import * as d3 from 'd3'

/**
 * This component renders the Gauge Charts on the **Competitive Positioning* page.
 */
export default {
  name: 'GaugeChart',
  props: {
    /** The data required for the gauge chart. It is an array of objects */
    data: {
      type: Array
    },
    /** The id for the chart */
    chartNumber: {
      type: Number
    }
  },
  mounted () {
    this.resizeWindow()
  },
  methods: {
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.data.length) {
          if (d3.select('.chart-gauge').node() != null) {
            // console.log('Resizing')
            this.renderChart()
          }
        }
      })
      this.observer.observe(document.querySelector('.chart-gauge'))
    },
    /**
     * This method renders the chart
     * @public
     */
    renderChart () {
      const chartId = '.chart-' + this.chartNumber
      d3.selectAll(chartId + ' > *').remove()
      const data = this.data

      const el = d3.select(chartId).selectAll('div').data(data).enter()

      const margin = {
        top: 0,
        right: 5,
        bottom: 0,
        left: 5
      }

      const containerWidth = document.querySelector(chartId).clientWidth
      let width = containerWidth / 3 - margin.left - margin.right - 10
      if (width > 150) {
        width = 150
      }
      const height = width
      const radius = Math.min(width, height) / 3
      const outerRadius = radius
      const innerRadius = radius - radius / 5.833
      // console.log(radius / 5.833);

      /*
                          Utility methods
                        */
      const percToDeg = function (perc) {
        return perc * 360
      }

      // eslint-disable-next-line no-unused-vars
      const percToRad = function (perc) {
        return degToRad(percToDeg(perc))
      }

      const degToRad = function (deg) {
        return (deg * Math.PI) / 180
      }

      // d3.selectAll
      // Create SVG element
      const div = el
        .append('div')
        .attr('width', width + margin.left + margin.right)
        .style('display', 'flex')

      const svg = div
        .data(data)
        .append('svg')
        // .attr("id", function (d, i) { return `svg-${i}` })
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)

      // Add layer for the panel
      const chart = svg
        .data(data)
        .append('g')
        .attr(
          'transform',
          'translate(' +
            (width + margin.left + margin.right) / 2 +
            ', ' +
            (height + margin.top + margin.bottom) / 2 +
            ')'
        )
      chart.append('path').attr('class', 'arc backgroundArc')
      chart.append('path').attr('class', 'arc arc2')

      const arc1 = d3.arc().outerRadius(outerRadius).innerRadius(innerRadius)
      const arc2 = d3
        .arc()
        .outerRadius(outerRadius)
        .innerRadius(outerRadius - outerRadius / 14.583375)

      const tickPoints = []
      for (let i = -120; i <= 120; i += 12) {
        tickPoints.push(i)
      }

      const ticks = chart
        // .data(data)
        .append('g')
        .attr('class', 'ticks')
        .selectAll('line')
        .data(tickPoints)
        .enter()
        .append('line')

      const readings = [
        {
          positionX: -outerRadius - outerRadius / 10.583375,
          positionY: outerRadius / 1.5,
          value: -5,
          fill: 'var(--inava-pink)'
        },
        {
          positionX: 0,
          positionY: -outerRadius - outerRadius / 7.72225,
          value: -0,
          fill: 'var(--gauge-chart-tick-value-color)'
        },
        {
          positionX: outerRadius + outerRadius / 35.3335,
          positionY: outerRadius / 1.5,
          value: 5,
          fill: 'var(--green)'
        }
      ]

      // eslint-disable-next-line no-unused-vars
      const tickValues = chart
        .selectAll('.chart-values')
        .data(readings)
        .enter()
        .append('text')
        .text(function (d) {
          return d.value
        })
        .attr('x', function (d) {
          return d.positionX
        })
        .attr('y', function (d) {
          return d.positionY
        })
        .attr('font-size', radius / 5)
        .attr('text-anchor', 'middle')
        .attr('fill', (d) => d.fill)
        .style('font-weight', 600)

      const gaugeMin = degToRad(-125)
      const gaugeMax = degToRad(125)
      const scale = function (score) {
        return degToRad(score * 24)
      }

      arc1
        .startAngle(gaugeMin)
        .endAngle(gaugeMax)
        .cornerRadius(outerRadius - innerRadius / 2)
      chart.select('.backgroundArc').attr('d', arc1)
      chart
        .select('.arc2')
        .attr('d', function (d) {
          const arc = d3
            .arc()
            .outerRadius(outerRadius)
            // .innerRadius(outerRadius - outerRadius / 14.583375)
            .innerRadius(innerRadius)
            .startAngle(0)
            .endAngle(scale(d.score))
            // .cornerRadius((outerRadius - innerRadius) / 2)
          return arc()
        })
        .attr('fill', function (d) {
          if (d.score > 0) {
            return 'var(--green)'
          } else {
            return 'var(--inava-pink)'
          }
        })

      ticks
        .data(tickPoints)
        .attr('x1', 0)
        .attr('x2', 0)
        .attr('y1', radius + radius / 29.16675)
        .attr('y2', radius + radius / 14.583375)
        .attr('stroke', 'gray')
        .attr('transform', function (d) {
          return `rotate(${d}), scale(-1)`
        })

      chart.append('circle').attr('class', 'startScore')
      chart
        .select('.startScore')
        .attr('fill', '#e1e6ef')
        // .attr('r', outerRadius / 23.3334)
        .attr('r', outerRadius / 14.5)
        .attr('stroke', function (d) {
          if (d.score > 0) {
            return 'var(--green)'
          } else {
            return 'var(--inava-pink)'
          }
        })
        .attr('stroke-width', outerRadius / 29.16)
        .attr('cx', function () {
          return gaugeMin + gaugeMax / 2 + outerRadius / 58.335
        })
        .attr('cy', function () {
          // return -outerRadius + outerRadius / 23.3334
          return -(innerRadius + outerRadius) / 2
        })

      chart.append('circle').attr('class', 'finalScore')
      chart
        .select('.finalScore')
        .attr('r', outerRadius / 14.583375)
        .attr('stroke', '#FFF')
        .attr('stroke-width', outerRadius / 19.4445)
        .attr('fill', function (d) {
          if (d.score > 0) {
            return 'var(--green)'
          } else {
            return 'var(--inava-pink)'
          }
        })
        // .attr('cx', function (d) {
        //   return d3.pointRadial(scale(d.score), outerRadius)[0] - d.score / 2
        // })
        // .attr('cy', function (d) {
        //   return (
        //     d3.pointRadial(scale(d.score), outerRadius)[1] +
        //     outerRadius / 38.889
        //   )
        // })
        .attr('cx', function (d) {
          return d3.pointRadial(scale(d.score), outerRadius)[0] - d.score
        })
        .attr('cy', function (d) {
          return (
            d3.pointRadial(scale(d.score), (outerRadius + innerRadius) / 2)[1]
          )
        })
        .attr('filter', 'drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.3))')

      // console.log("chart", chart);

      chart
        .append('text')
        .text(function (d) {
          // console.log("Data is:", d);
          if (d.score >= 0) {
            return '+' + d.score
          } else {
            return d.score
          }
        })
        .attr('x', 0)
        .attr('y', 0)
        .attr('text-anchor', 'middle')
        .attr('font-size', radius / 3)
        .attr('fill', function (d) {
          if (d.score > 0) {
            return 'var(--green)'
          } else {
            return 'var(--inava-pink)'
          }
        })
        .style('font-weight', 600)

      chart
        .append('text')
        .text(function (d) {
          return d.topic
        })
        .attr('class', 'chart-label')
        .attr('text-anchor', 'middle')
        .attr('fill', 'var(--gauge-chart-label-color)')
        .attr('font-size', radius / 4.75)
        .style('font-weight', 600)
        .attr('x', 0)
        .attr('y', radius)
        .call(wrap, width / 1.5)

      function wrap (text, width) {
        // console.log(text)
        text.each(function () {
          const text = d3.select(this)
          let words = text.text().split(/[\s+]/).reverse()
          let word
          let line = []
          let lineNumber = 0
          const lineHeight = 1.4 // ems
          const x = text.attr('x')
          const y = text.attr('y')
          const dy = text.attr('dy') ? text.attr('dy') : 0
          let tspan = text
            .text(null)
            .append('tspan')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', dy)
          while ((word = words.pop())) {
            if (word.length > 18) {
              const remainingWord = word.slice(18)
              word = word.slice(0, 18)
              if (lineNumber === 0) {
                word += '-'
              }
              words.push(remainingWord + ' ')
            }
            line.push(word)
            tspan.text(line.join(' '))
            if (tspan.node().getComputedTextLength() > width) {
              line.pop()
              tspan.text(line.join(' '))
              line = [word]
              // console.log('line', line)
              if (lineNumber < 1) {
                tspan = text
                  .append('tspan')
                  .attr('x', x)
                  .attr('y', y)
                  .attr('dy', ++lineNumber * lineHeight + 'em')
                  .text(word)
              } else {
                word = '...'
                tspan = text
                  .append('tspan')
                  .attr('dx', '0')
                  .attr('dy', 0)
                  .text(word)
                words = []
              }
            }
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chart-gauge {
  /* width: 300px; */
  width: 100%;
  display: flex;
  margin: 0px auto;
  flex-wrap: wrap;
  justify-content: center;
}

.chart-filled {
  fill: steelblue;
}

.chart-empty {
  fill: #dedede;
}

.needle,
.needle-center {
  fill: #464a4f;
}

svg {
  font: 10px sans-serif;
}

::v-deep .backgroundArc {
  fill: var(--gauge-chart-background-arc-color);
}
</style>
